import {
  AddressElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions,
  StripePaymentElementOptions,
  StripeLinkAuthenticationElementChangeEvent,
} from "@stripe/stripe-js";

import { debounce } from "lodash";
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import locales from "../../../config/locales";
import intl from "../../services/intl";
import { navigate } from "../../services/navigation";
import CartProduct from "../../store/cart-product/model";
import { trackCartUpdated } from "../../utils/tracking/cart";
import cartProductSelectors from "../../store/cart-product/selectors";
import userSelectors from "../../store/user/selectors";
import Cart, { RecurringBillingDetails } from "../../store/cart/model";
import { getUserTraits } from "../../utils/currentUser";
import metrics from "../../utils/metrics";
import { Icons } from "../../utils/react-svg";
import {
  AddressVerificationOutput,
  IPostalAddress,
  StripeFormattedAddress,
} from "../../utils/types/validateAddressTypes";
import {
  mapPostalAddressToStripeFormat,
  validateAddress,
} from "../../utils/validateAddress";
import RitualButton from "../global/RitualButton";
import AddressSuggestionInline from "./AddressSuggestionInline";
import AddressSuggestionModal from "./AddressSuggestionModal";
import MarketingConsent from "./MarketingConsent";
import CheckoutTerms from "./CheckoutTerms";
import {
  finalizePurchase,
  getApplePayOption,
  setLocalStorage,
  stripeElementsConfig,
  tryApiPurchase,
  tryCreateConfirmationToken,
  tryElementsSubmit,
  tryHandleNextAction,
  createTruemedPaymentSession,
  TruemedPaymentSession,
} from "./utils";
import useVariation from "../../hooks/useVariation";
import {
  createDebouncedIdentify,
  createDebouncedEmailTrack,
} from "../../utils/userIdentification";
import TruemedIframeModal from "./TruemedIframeModal";
import * as Sentry from "@sentry/gatsby";

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 4px;
  padding-right: 4px;

  @media (min-width: 933px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--spacing-1);

    h2,
    p {
      margin-bottom: 0;
    }
  }
`;

const Title = styled.h2`
  &.spacing-bottom {
    margin-bottom: var(--spacing-1);
  }

  &.spacing-top {
    margin-top: var(--spacing-3);
  }
`;

const SafetyPromise = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SafeAndSecureContainer = styled.div`
  margin-top: var(--spacing-0_75);
  margin-bottom: var(--spacing-0_5);
`;

const SafeSecureText = styled.span`
  margin-left: var(--spacing-0_25);
`;

const ellipsisDotFade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const EllipsisDot = styled.span`
  opacity: 0;
  animation: ${ellipsisDotFade} 1.5s linear infinite;
`;

const LoadingButtonText = styled.span`
  span:nth-child(1) {
    animation-delay: 0s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }
`;

const GATSBY_GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY!;

const autocomplete = {
  mode: "google_maps_api",
  apiKey: GATSBY_GOOGLE_MAPS_API_KEY,
};

interface StripeElementsCheckoutProps {
  address: StripeFormattedAddress | null;
  setAddress: React.Dispatch<
    React.SetStateAction<StripeFormattedAddress | null>
  >;
  name: string | null;
  setName: React.Dispatch<React.SetStateAction<string | null>>;
  addressValidationResult: AddressVerificationOutput | null;
  setAddressValidationResult: React.Dispatch<
    React.SetStateAction<AddressVerificationOutput | null>
  >;
  useGoogleMapsAddressValidation: boolean;
  setShowErrorBanner: React.Dispatch<React.SetStateAction<boolean>>;
  setLastPaymentError: React.Dispatch<React.SetStateAction<any>>;
  handleAddressChange: (
    addressInput: StripeFormattedAddress,
    cart: Cart,
  ) => Promise<void>;
  activeCart: Cart;
  activeCartProducts: CartProduct[];
  disableSubmit: boolean;
  recurringBillingDetails: RecurringBillingDetails;
  canCheckout: boolean;
  tryingPurchase: boolean;
  setTryingPurchase: React.Dispatch<React.SetStateAction<boolean>>;
  handleOrderCompleted: Function;
  paymentMethod?: string | null;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string | null>>;
}

interface IdentifyUserArgs {
  email: string;
  marketingPreference?: boolean | null;
}

const StripeElementsCheckout = ({
  address,
  setAddress,
  name,
  setName,
  addressValidationResult,
  setAddressValidationResult,
  useGoogleMapsAddressValidation,
  setShowErrorBanner,
  setLastPaymentError,
  handleAddressChange,
  activeCart,
  activeCartProducts,
  disableSubmit,
  recurringBillingDetails,
  canCheckout,
  tryingPurchase,
  setTryingPurchase,
  handleOrderCompleted,
  paymentMethod,
  setPaymentMethod,
}: StripeElementsCheckoutProps) => {
  const [email, setEmail] = useState("");
  const previousEmail = useRef("");
  const [addressComplete, setAddressComplete] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  const debouncedIdentify = useMemo(() => createDebouncedIdentify(1000), []);
  const debouncedEmailTrack = useMemo(
    () => createDebouncedEmailTrack(1000),
    [],
  );

  const identifyUserWithPreferences = useCallback(
    async ({ email, marketingPreference }: IdentifyUserArgs) => {
      await debouncedIdentify(email, marketingPreference);

      if (!email || email === previousEmail.current) return;

      debouncedEmailTrack(email, "Checkout Page");
      previousEmail.current = email;
    },
    [debouncedIdentify, debouncedEmailTrack],
  );

  const [showAddressComponent, setShowAddressComponent] = useState(true);
  const [acceptedAddressSelection, setAcceptedAddressSelection] =
    useState(false);
  const [showAddressSuggestionModal, setShowAddressSuggestionModal] =
    useState(false);
  const [seenAddressSuggestionModal, setSeenAddressSuggestionModal] =
    useState(false);
  const [marketingPreference, setMarketingPreference] = useState<
    boolean | null
  >(null);
  const [nameInvalid, setNameInvalid] = useState(true);
  const [nameTooLong, setNameTooLong] = useState(true);
  const [truemedIframeUrl, setTruemedIframeUrl] = useState<string | null>(null);
  const [truemedPurchase, setTruemedPurchase] = useState<Pick<
    TruemedPaymentSession,
    "first_name" | "last_name" | "user_id"
  > | null>(null);

  const elements = useElements();
  const stripe = useStripe();

  const activeCartRecurringItems = useSelector(
    cartProductSelectors.activeCartRecurringItems,
  );

  const activeUser = useSelector(userSelectors.activeUser);
  const activeCartRecurringAny = activeCartRecurringItems.length > 0;

  const localeId = intl.locale;
  const locale = locales[localeId]!;

  const activeCountry = locale.storeCode;

  const resetCheckoutState = useCallback(() => {
    setEmail("");
    setAddressComplete(false);
    setPaymentComplete(false);
    setShowAddressComponent(true);
    setAcceptedAddressSelection(false);
    setShowAddressSuggestionModal(false);
    setSeenAddressSuggestionModal(false);
    setMarketingPreference(null);
    setNameInvalid(true);
    setNameTooLong(true);
    setAddress(null);
    setName(null);
    setAddressValidationResult(null);
    setShowErrorBanner(false);
    setLastPaymentError(null);
    setTryingPurchase(false);
  }, [
    setEmail,
    setAddressComplete,
    setPaymentComplete,
    setShowAddressComponent,
    setAcceptedAddressSelection,
    setShowAddressSuggestionModal,
    setSeenAddressSuggestionModal,
    setMarketingPreference,
    setNameInvalid,
    setNameTooLong,
    setAddress,
    setName,
    setAddressValidationResult,
    setShowErrorBanner,
    setLastPaymentError,
    setTryingPurchase,
  ]);

  let addressOptions = {
    mode: "shipping",
    allowedCountries: [activeCountry],
    ...(GATSBY_GOOGLE_MAPS_API_KEY && { autocomplete }),
  } as StripeAddressElementOptions;

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    ...(recurringBillingDetails && {
      applePay: getApplePayOption(recurringBillingDetails, activeCart),
    }),
  };

  const truemedEnabled = useVariation("enable-hsa-fsa");

  useEffect(() => {
    if (!elements || !activeCart) {
      return;
    }
    elements.update(
      stripeElementsConfig(
        activeCart,
        activeCartRecurringAny,
        localeId,
        truemedEnabled,
      ),
    );
  }, [elements, activeCart, activeCartRecurringAny, truemedEnabled, localeId]);

  useEffect(() => {
    if (activeUser) return;

    setMarketingPreference(locale["newsletter-checkbox-enabled"]);
  }, [locale, activeUser]);

  useEffect(() => {
    setNameInvalid(
      Boolean(addressComplete && name && name.split(" ").length < 2),
    );
    setNameTooLong(Boolean(addressComplete && name && name.length > 35));
  }, [name, addressComplete]);

  useEffect(() => {
    setShowAddressComponent(false);
    setTimeout(() => setShowAddressComponent(true), 0);
  }, [acceptedAddressSelection]);

  const clearSessionStorage = () => {
    sessionStorage.removeItem("rit-cart_id");
    sessionStorage.removeItem("rit-marketing_preference");
    sessionStorage.removeItem("rit-purchased_cart");
    sessionStorage.removeItem("rit-purchased_cart_products");
    sessionStorage.removeItem("rit-order_number");
    sessionStorage.removeItem("rit-reset_password_token");
    sessionStorage.removeItem("rit-express_checkout_confirm_event");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      if (!stripe || !elements) {
        setTryingPurchase(false);
        return;
      }

      setTryingPurchase(true);
      setLastPaymentError(null);
      setShowErrorBanner(false);
      setShowAddressSuggestionModal(false);

      if (marketingPreference !== null) {
        sessionStorage.setItem(
          "rit-marketing_preference",
          marketingPreference.toString(),
        );
      }

      metrics.track("Purchase Attempted", {
        payment_method: paymentMethod,
      });

      if (!addressComplete || !paymentComplete) {
        setTryingPurchase(false);
        return;
      }

      if (
        (addressValidationResult?.confirmAddress ||
          addressValidationResult?.fixAddress) &&
        !addressValidationResult?.perfectAddress &&
        !seenAddressSuggestionModal
      ) {
        setShowAddressSuggestionModal(true);
        setTryingPurchase(false);
        return;
      }

      const { error: submitError, selectedPaymentMethod } =
        await tryElementsSubmit(elements);
      if (submitError) {
        setTryingPurchase(false);
        return;
      }

      await handleAddressChange(
        { ...address!, validated: !addressValidationResult?.fixAddress },
        activeCart,
      );

      const { id: cartId } = activeCart;
      sessionStorage.setItem("rit-cart_id", cartId);
      sessionStorage.setItem(
        "rit-purchased_cart_products",
        JSON.stringify(activeCartProducts),
      );

      if (
        selectedPaymentMethod &&
        selectedPaymentMethod === process.env.GATSBY_TRUEMED_PAYMENT_METHOD_ID
      ) {
        const paymentSessionResponse = await createTruemedPaymentSession(
          activeCart.id,
          name!,
          email,
        );

        if (paymentSessionResponse.success) {
          setLocalStorage(paymentSessionResponse.data);
          let confirmEvent = {
            billingDetails: { email: email },
            shippingAddress: address,
            paymentMethod: { truemed: true },
          };
          sessionStorage.setItem(
            "rit-express_checkout_confirm_event",
            JSON.stringify(confirmEvent),
          );
          metrics.track("Truemed Modal Presented", {
            cart_id: activeCart.id,
          });

          const { first_name, last_name, user_id } =
            paymentSessionResponse.data;
          setTruemedPurchase({ first_name, last_name, user_id });

          setTruemedIframeUrl(paymentSessionResponse.data.redirect_url);
          return;
        } else {
          setLastPaymentError(paymentSessionResponse.error.data);
          setShowErrorBanner(true);
          setTryingPurchase(false);
          return;
        }
      }

      const { error: confirmationTokenError, confirmationToken } =
        await tryCreateConfirmationToken(stripe, elements);
      if (confirmationTokenError || !confirmationToken) {
        setTryingPurchase(false);
        return;
      }

      const { response: purchase, error } = await tryApiPurchase({
        confirmationToken: confirmationToken,
        cartId,
      });
      if (error || !purchase) {
        const paymentError = error?.errors?.[0];
        if (paymentError?.source === "Stripe") {
          setLastPaymentError(paymentError);
        }
        setShowErrorBanner(true);
        setTryingPurchase(false);
        return;
      }

      setLocalStorage(purchase);

      let confirmEvent = {
        billingDetails:
          confirmationToken.payment_method_preview.billing_details,
        paymentMethod: confirmationToken.payment_method_preview,
        shippingAddress: confirmationToken.shipping,
        expressPaymentType: confirmationToken.payment_method_preview.type,
        confirmationToken: confirmationToken,
      };
      sessionStorage.setItem(
        "rit-express_checkout_confirm_event",
        JSON.stringify(confirmEvent),
      );

      sessionStorage.setItem("rit-checkout", "home");

      metrics.identify(purchase.user_id, {
        first_name: purchase.first_name,
        last_name: purchase.last_name,
        email,
        ...(!activeUser && { marketingPreference }),
      });

      if (purchase.requires_action && purchase.client_secret) {
        const { error: handleNextActionError } = await tryHandleNextAction(
          purchase.client_secret,
          stripe,
        );

        if (handleNextActionError) {
          setLastPaymentError(handleNextActionError);
          setShowErrorBanner(true);
          setTryingPurchase(false);
          return;
        }

        await finalizePurchase(cartId);
      }

      handleOrderCompleted({
        marketingPreference,
        paymentMethod,
      });

      resetCheckoutState();
      navigate("/confirmation");
    } catch (e) {
      Sentry.captureException(e);
      metrics.track("Purchase Failed", {
        reason: e,
      });
      clearSessionStorage();
      setShowErrorBanner(true);
      setTryingPurchase(false);
    }
  };

  const handleTruemedSuccess = useCallback(() => {
    metrics.track("Truemed Modal Completed", {
      cart_id: activeCart.id,
    });

    const { first_name, last_name, user_id } = truemedPurchase!;
    metrics.identify(user_id, {
      first_name,
      last_name,
      email,
      marketingPreference,
    });

    setTruemedIframeUrl(null);
    setTryingPurchase(false);

    handleOrderCompleted({
      marketingPreference,
      paymentMethod: "truemed",
    });

    resetCheckoutState();
    navigate("/confirmation");
  }, [
    resetCheckoutState,
    setTryingPurchase,
    marketingPreference,
    handleOrderCompleted,
    activeCart,
    truemedPurchase,
    email,
  ]);

  const debouncedHandleAddressChangeCallback = useMemo(
    () =>
      debounce(async (address, cart) => {
        const addressValidationResult = await validateAddress(
          address,
          useGoogleMapsAddressValidation,
        );
        if (!addressValidationResult) return;
        setAddressValidationResult(addressValidationResult);
        const validated = !addressValidationResult?.fixAddress;
        await handleAddressChange({ ...address, validated }, cart);
      }, 500),
    // eslint-disable-next-line
    [],
  );

  const acceptAddressSuggestion = (
    postalAddress: IPostalAddress | null | undefined,
  ) => {
    if (!postalAddress) return;
    const acceptedAddress = mapPostalAddressToStripeFormat(postalAddress);
    if (!acceptedAddress) return;
    setAddress(acceptedAddress);
    handleAddressChange({ ...acceptedAddress, validated: true }, activeCart);
    setAcceptedAddressSelection(true);
    setTimeout(() => {
      setAcceptedAddressSelection(false);
      setAddressValidationResult(null);
    }, 0);
  };

  const onAddressUpdate = async (e: StripeAddressElementChangeEvent) => {
    setAddress(e.value.address);
    setName(e.value.name);
    setAddressComplete(e.complete);

    if (e.complete && !nameInvalid && !nameTooLong) {
      await debouncedHandleAddressChangeCallback(e.value.address, activeCart);
    }
  };

  const onPaymentChange = (e: any) => {
    const {
      complete,
      value: { type },
    } = e;
    setPaymentComplete(complete);

    const selectedPaymentMethod =
      type === process.env.GATSBY_TRUEMED_PAYMENT_METHOD_ID ? "truemed" : type;

    setPaymentMethod(selectedPaymentMethod);
    if (complete) {
      metrics.track("Payment Info Entered", {
        type: selectedPaymentMethod,
      });
    }
  };

  const onTruemedModalClose = () => {
    metrics.track("Truemed Modal Abandoned", {
      cart_id: activeCart.id,
    });

    setTruemedIframeUrl(null);
    setTryingPurchase(false);
  };

  const onMarketingPreferenceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setMarketingPreference(e.target.checked);
    identifyUserWithPreferences({
      email,
      marketingPreference: e.target.checked,
    });
  };

  if (address) {
    addressOptions = {
      ...addressOptions,
      defaultValues: {
        name,
        address: { ...address, country: activeCountry },
      },
    };
  }

  const disableSubmitButton =
    !canCheckout ||
    !addressComplete ||
    !paymentComplete ||
    disableSubmit ||
    nameInvalid ||
    nameTooLong ||
    addressValidationResult?.failsBasicValidations;

  const { email: currentUserEmail } = getUserTraits();
  const linkAuthenticationElementOptions = {
    defaultValues: {
      email: currentUserEmail || "",
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Heading>
          <Title className="typography-lead2">Your Details</Title>
        </Heading>
        <LinkAuthenticationElement
          options={linkAuthenticationElementOptions}
          onChange={(e: StripeLinkAuthenticationElementChangeEvent) => {
            if (e.complete) {
              setEmail(e.value.email);
            }
          }}
          onBlur={() => {
            identifyUserWithPreferences({
              email,
              marketingPreference,
            });

            trackCartUpdated();
          }}
        />
        {!activeUser && (
          <MarketingConsent
            marketingPreference={marketingPreference}
            onMarketingPreferenceChange={onMarketingPreferenceChange}
          />
        )}

        <Title className="typography-lead2 spacing-top spacing-bottom">
          Delivery
        </Title>
        {!showAddressComponent && <p>...</p>}
        {showAddressComponent && (
          <AddressElement options={addressOptions} onChange={onAddressUpdate} />
        )}

        {addressValidationResult && (
          <AddressSuggestionInline
            acceptAddressSuggestion={acceptAddressSuggestion}
            addressValidationResult={addressValidationResult}
            nameInvalid={nameInvalid}
            nameTooLong={nameTooLong}
          />
        )}
        <Title className="typography-lead2 spacing-top spacing-bottom">
          Payment
        </Title>
        <PaymentElement
          onChange={onPaymentChange}
          options={paymentElementOptions}
        />

        <CheckoutTerms recurringBillingDetails={recurringBillingDetails} />

        <RitualButton
          disabled={disableSubmitButton}
          aria-disabled={disableSubmitButton}
          aria-live={tryingPurchase ? "polite" : undefined}
          onClick={handleSubmit}
          className="fullwidth"
          isLink={false}
        >
          {tryingPurchase ? (
            <LoadingButtonText>
              Payment Processing<EllipsisDot>.</EllipsisDot>
              <EllipsisDot>.</EllipsisDot>
              <EllipsisDot>.</EllipsisDot>
            </LoadingButtonText>
          ) : (
            "Purchase"
          )}
        </RitualButton>
      </form>
      <SafetyPromise>
        <SafeAndSecureContainer>
          <Icons.LockShield />
          <SafeSecureText className="typography-caption">
            Safe and secure checkout
          </SafeSecureText>
        </SafeAndSecureContainer>
        <Icons.PoweredByStripe />
      </SafetyPromise>
      {showAddressSuggestionModal &&
        !seenAddressSuggestionModal &&
        !addressValidationResult?.perfectAddress && (
          <AddressSuggestionModal
            setAcceptedAddressSelection={setAcceptedAddressSelection}
            setAddress={setAddress}
            onRequestClose={() => {
              setShowAddressSuggestionModal(false);
              setSeenAddressSuggestionModal(true);
            }}
            setShowAddressSuggestionModal={setShowAddressSuggestionModal}
            suggestionModalOpen={showAddressSuggestionModal}
            addressValidationResult={addressValidationResult}
            setSeenAddressSuggestionModal={setSeenAddressSuggestionModal}
            handleAddressChange={handleAddressChange}
            activeCart={activeCart}
          />
        )}
      {truemedIframeUrl && (
        <TruemedIframeModal
          truemedIframeUrl={truemedIframeUrl}
          cartId={activeCart.id}
          onSuccess={handleTruemedSuccess}
          onRequestClose={onTruemedModalClose}
        />
      )}
    </>
  );
};

export default StripeElementsCheckout;
