import { useDispatch } from "react-redux";
import { addProductsToCart, addProductToCart } from "../../store/cart/actions";
import cartService from "../../services/cart";
import DiscountButton from "./DiscountButton";
import RitualButton from "../global/RitualButton";
import metrics from "../../utils/metrics";
import DiscountButtonAnimated from "./DiscountButtonAnimated";
import useVariation from "../../hooks/useVariation";

type Props = {
  ritualProductOffer?: {
    product_offer_id: string;
    slug: string;
  };
  location: string;
  oos: boolean;
  price?: number;
  discountPrice?: number;
  animated?: boolean;
  className?: string;
  isVisible?: boolean;
  futurePlans?: {
    plan_id: string;
  }[];
};

const AddBundleButton = ({
  ritualProductOffer,
  location,
  oos,
  price,
  discountPrice,
  animated,
  className,
  isVisible,
  futurePlans,
}: Props) => {
  const dispatch = useDispatch();
  const bundleExperienceOverhaulFlag = useVariation(
    "bundle-experience-overhaul",
    {},
  );
  const bundleExperienceOverhaulEnabled =
    !!bundleExperienceOverhaulFlag?.bundle_promo_slug;

  if (!ritualProductOffer) return null;

  if (oos) {
    return (
      <RitualButton disabled={true} className="fullwidth">
        Out of Stock
      </RitualButton>
    );
  }

  const plansToAdd = futurePlans?.map((plan: any) => {
    return {
      quantity: 1,
      planId: plan.plan_id,
    };
  });

  const handleAddBundle = (e: any) => {
    if (bundleExperienceOverhaulEnabled && plansToAdd) {
      dispatch(
        addProductsToCart(plansToAdd, {
          product_offer_slug: ritualProductOffer?.slug,
          location,
        }),
      );
    } else {
      dispatch(
        addProductToCart(
          {
            productOfferId: ritualProductOffer.product_offer_id,
          },
          {
            location,
          },
        ),
      );
    }

    metrics.track("CTA Clicked", {
      location,
      title: "Add to Cart",
    });

    cartService.openCart(e);
  };

  const discountButtonProps = {
    to: "/cart",
    noNavigate: true,
    onClick: handleAddBundle,
    price: price,
    prefix: "Add to Cart",
    className,
    showDash: !!price,
    discountPrice,
  };

  if (animated) {
    return (
      <DiscountButtonAnimated {...discountButtonProps} isVisible={isVisible} />
    );
  }

  return <DiscountButton {...discountButtonProps} />;
};

export default AddBundleButton;
