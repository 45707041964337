import { graphql } from "gatsby";
import { useEffect } from "react";
import styled from "styled-components";

// Hooks
import GridColumn from "../components/grid/GridColumn";
import GridContainer from "../components/grid/GridContainer";
import GridRow from "../components/grid/GridRow";
import BundleProductTile from "../components/plp/BundleProductTile";
import EditorialBlocksLarge from "../components/plp/EditorialBlockLarge";
import { default as EditorialBlocks } from "../components/plp/EditorialBlocks";
import EditorialBlockVideo from "../components/plp/EditorialBlockVideo";
import ForwardPathsComponent from "../components/plp/ForwardPathsComponent";
import { default as Hero } from "../components/plp/Hero";
import { default as SEOBlock } from "../components/plp/SEOBlock";
import SingleProductTile from "../components/plp/SingleProductTile";
import TabGroup from "../components/plp/TabGroup";
import ValuePropsTicker from "../components/plp/ValuePropsTicker";
import ListPageSEO from "../components/seo/ListPage";
import useContentfulVariation from "../hooks/contentful/useContentfulVariation";
import usePlpNavigation from "../hooks/plp/usePlpNavigation";
import useProductListViewed from "../hooks/segment/useProductListViewed";
import { responsive } from "../utils/style";
import Footnotes from "../components/Footnotes";

const Spacer = styled.div`
  margin-top: 58px;

  ${responsive.md`
    margin-top: 70px;
  `}
`;

const PageWrapper = styled.div`
  min-height: 100vh;

  .spacing-bottom {
    padding-bottom: var(--spacing-2, 48px);
  }

  ${responsive.sm`
    .spacing-bottom {
      padding-bottom: unset;
    }
  `}
`;

const HeroContainer = styled.div`
  display: block;
  margin-bottom: 24px;
  padding: 0 var(--grid-margin-xs);

  @media (min-width: 993px) {
    padding: 0 var(--grid-margin-m);
  }
`;

const TopRadialGradient = styled.span`
  display: none;

  ${responsive.sm`
    display: block;
  `};

  width: 603px;
  height: 603px;
  border-radius: 50%;
  z-index: -1;

  position: absolute;
  top: 304px;
  right: -315px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 230, 102, 0.6) 17.67%,
    rgba(255, 230, 102, 0) 100%
  );

  ${responsive.md`
    width: 733px;
    height: 733px;

    top: 402px;
    right: -386px;
  `};
`;

const BottomRadialGradient = styled(TopRadialGradient)`
  z-index: 1;
  display: block;

  width: 474px;
  height: 474px;
  top: 0;
  right: calc(474px * -0.5);

  ${responsive.md`
    display: block;
    width: 977px;
    height: 977px;

    top: calc(977px * -0.5);
    right: calc(977px * -0.5);
  `};
`;

const itemSizes = {
  Small: {
    xs: { size: 3 },
    s: { size: 3 },
    m: { size: 3 },
  },
  Medium: {
    xs: { size: 6 },
    s: { size: 6 },
    m: { size: 6 },
  },
  Large: {
    xs: { size: 6 },
    s: { size: 12 },
    m: { size: 12 },
  },
};

const editorialItemSizes = {
  Small: {
    xs: { size: 6 },
    s: { size: 3 },
    m: { size: 3 },
  },
  Medium: {
    xs: { size: 6 },
    s: { size: 3 },
    m: { size: 6 },
  },
  Large: {
    xs: { size: 6 },
    s: { size: 12 },
    m: { size: 12 },
  },
};

const fallbackSizes = {
  xs: { size: 6 },
  s: { size: 3 },
  m: { size: 3 },
};

const TileComponent = (item) => {
  const { type, content, size } = item;

  // No content
  if (!content) return () => null;

  // Single Products
  if (type === "Product Tile" && content?.sku) return SingleProductTile;
  // Bundled Products
  if (type === "Product Tile" && content?.ritualProductOffer?.product_offer_id)
    return BundleProductTile;
  // Editorial Blocks
  if (type === "Editorial Block" && content?.videoContent)
    return EditorialBlockVideo;
  if (type === "Editorial Block" && size !== "Large") return EditorialBlocks;
  if (type === "Editorial Block" && size === "Large")
    return EditorialBlocksLarge;

  // Incompatible or empty type
  return () => null;
};

const GridItems = ({
  items,
  currency,
  onItemClick,
  isBundleLandingPage = false,
}) => {
  if (!items) return null;

  const renderedItems = [...items];

  return (
    <GridContainer>
      <GridRow addClass="grid-row-plp">
        {renderedItems.map((item, i) => {
          const { size, type, content, name } = item;
          const isEditorialSmall =
            type === "Editorial Block" && size === "Small";
          const columnSizes = isEditorialSmall
            ? editorialItemSizes[size] || fallbackSizes
            : itemSizes[size] || fallbackSizes;
          const Tile = TileComponent(item);

          // Conditionally choose loading type, assign first few items as "eager" to help with LCP score
          const loading = i < 3 ? "eager" : "lazy";

          const isLargeContentBlock =
            type === "Editorial Block" &&
            size === "Large" &&
            !name.includes("Text Only");

          return (
            <GridColumn key={`${i}-${content?.contentful_id}`} {...columnSizes}>
              {isLargeContentBlock ? (
                <div className={"spacing-bottom"}>
                  <Tile item={item} currency={currency} loading={loading} />
                </div>
              ) : (
                <Tile
                  item={item}
                  currency={currency}
                  loading={loading}
                  isBundleLandingPage={isBundleLandingPage}
                />
              )}
            </GridColumn>
          );
        })}
      </GridRow>
    </GridContainer>
  );
};

const useProductListViewedOnLoad = (title, items) => {
  const productListViewed = useProductListViewed(
    title,
    items
      .filter((item) => item.content?.ritualProduct || item.content?.slug)
      .map((item) =>
        item.content.ritualProduct
          ? { productSku: item.content.ritualProduct.sku }
          : { productOfferSlug: item.content.slug },
      ),
  );
  useEffect(productListViewed, []); // eslint-disable-line react-hooks/exhaustive-deps
};

const ProductListPage = ({ data, pageContext }) => {
  const { currency } = pageContext;
  const { contentfulPageVariation } = data;
  const { flag, defaultContent, contentVariations, slug } =
    contentfulPageVariation;
  const contentfulPlp = useContentfulVariation(
    flag,
    defaultContent,
    contentVariations,
  );

  const {
    description,
    title,
    items,
    forwardPathsType,
    seoHeadline,
    seoDescription,
    seoFootnote,
    parentPlp,
    footnote,
  } = contentfulPlp;

  const { activePanel, links, isPrimaryPage } = usePlpNavigation(
    defaultContent?.contentful_id,
    contentfulPlp,
    slug,
  );

  useProductListViewedOnLoad(title, items);

  const seoProps = {
    path: slug,
    title: title,
    description: description,
    items,
    image: contentfulPlp.heroImage?.url,
  };

  const isBundleLandingPage = !parentPlp && title === "Bundle & Save";

  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <ListPageSEO seoProps={seoProps} currency={currency} />
      <Spacer />

      <PageWrapper>
        <HeroContainer>
          <Hero
            contentfulPlp={contentfulPlp}
            activePanel={activePanel}
            isPrimaryPage={isPrimaryPage}
          />
        </HeroContainer>
        <TabGroup links={links} activePlpId={defaultContent.contentful_id} />
        <GridItems
          items={items}
          currency={currency}
          isBundleLandingPage={isBundleLandingPage}
        />
        {forwardPathsType && (
          <ForwardPathsComponent category={forwardPathsType} />
        )}
        <ValuePropsTicker>
          <BottomRadialGradient />
        </ValuePropsTicker>
        <SEOBlock
          headline={seoHeadline}
          description={seoDescription}
          footnote={seoFootnote}
        />
        <Footnotes footnotes={footnote} />
      </PageWrapper>

      <TopRadialGradient />
    </div>
  );
};

export const query = graphql`
  fragment ProductListPageFragment on ContentfulPlp {
    contentful_id
    title
    linkSets {
      contentful_id
      title
      navigationpanel {
        contentful_id
        title
      }
      links {
        contentful_id
        text
        to {
          ... on ContentfulPageVariation {
            contentful_id
            slug
            defaultContent {
              contentful_id
            }
          }
        }
      }
    }
    parentPlp {
      ... on ContentfulPageVariation {
        contentful_id
        slug
        defaultContent {
          title
          contentful_id
        }
      }
    }
    heroImage {
      gatsbyImageData(
        placeholder: DOMINANT_COLOR
        width: 1200
        layout: CONSTRAINED
        quality: 90
      )
      description
      url
    }
    heroVideo {
      ...videoContent
    }
    pageTitle {
      raw
    }
    description
    items {
      name
      type
      size
      tileFlag {
        content
        backgroundColor
        textColor
      }
      titleFlag {
        content
        backgroundColor
        textColor
      }
      awardBadge {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 80
          quality: 100
        )
        description
      }
      content {
        ... on ContentfulProduct {
          contentful_id
          name {
            name
          }
          summary
          slug
          path
          sku
          stockStatus
          productSubhead
          shopImages {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 1200
              quality: 90
            )
            description
          }
          socialImage {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
          ...RitualProductPricingData
        }
        ... on ContentfulProductBundle {
          contentful_id
          title
          buildBundlePdp
          slug
          labels
          ritualProductOffer {
            product_offer_id
            name
            slug
            ritualProductOfferPlans {
              product_offer_plan_id
              ritualInitialPlan {
                amount
                plan_id
                currency
              }
              ritualFuturePlan {
                amount
                plan_id
                currency
              }
            }
          }
          shortDescription: description
          featuredImage {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 1200
              quality: 90
            )
            description
          }
          products {
            name {
              name
            }
            shortDescription
            sku
            image: cartImage {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: DOMINANT_COLOR
                width: 400
                quality: 90
              )
              description
            }
            supplementFacts {
              simpleIngredients {
                name
              }
              servingsPerContainer
              servingSize
            }
          }
        }
        ... on ContentfulEditorialBlockContent {
          contentful_id
          eyebrow
          headline {
            raw
          }
          description {
            raw
          }
          footnote
          backgroundImage {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              width: 800
              quality: 90
            )
            description
          }
          ctaText
          ctaSlug
          images {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              width: 1200
              quality: 90
            )
            description
          }
          videoContent {
            ...videoContent
            hasAudio
          }
          backgroundColor
        }
      }
    }
    forwardPathsType
    seoHeadline {
      raw
    }
    seoDescription {
      raw
    }
    seoFootnote
    footnote {
      childMarkdownRemark {
        html
      }
    }
  }

  query ScalableProductListPageQuery(
    $locale: String!
    $contentful_id: String!
  ) {
    contentfulPageVariation(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      flag
      slug
      defaultContent {
        ...ProductListPageFragment
      }
      contentVariations {
        ...ProductListPageFragment
      }
    }
  }
`;

export default ProductListPage;
