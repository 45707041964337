import { useMemo } from "react";
import usePromotionDiscount from "./usePromotionDiscount";

export const useMultiplePromotionDiscounts = (
  promotion: any,
  planIds: string[],
) => {
  // Hooks must always be called in the same order
  const discount0 = usePromotionDiscount(promotion, planIds[0] || "");
  const discount1 = usePromotionDiscount(promotion, planIds[1] || "");
  const discount2 = usePromotionDiscount(promotion, planIds[2] || "");

  return useMemo(() => {
    const discounts: Record<
      string,
      ReturnType<typeof usePromotionDiscount>
    > = {};

    if (planIds[0]) discounts[planIds[0]] = discount0;
    if (planIds[1]) discounts[planIds[1]] = discount1;
    if (planIds[2]) discounts[planIds[2]] = discount2;

    return discounts;
  }, [planIds, discount0, discount1, discount2]);
};
