import sumBy from "lodash/sumBy";
import { getPlanForProductSku } from "./planToProduct";

// Services
import intl from "../services/intl";
import siteStore from "../services/siteStore";

export function getBundleTotal(bundle) {
  const plans = bundle.products.map((product) =>
    getPlanForProductSku(product.sku),
  );

  return sumBy(plans, (p) => (p && p.amount) || 0) / 100;
}

export function getBundleCartLimit() {
  return siteStore.maxOrderItems;
}

export function getPlanIdAndQuantityArray(planIds) {
  const planToQuantityMap = {};
  planIds.forEach((planId) => {
    if (!planToQuantityMap[planId]) {
      planToQuantityMap[planId] = 0;
    }
    planToQuantityMap[planId]++;
  });

  return Object.keys(planToQuantityMap).map((planId) => {
    return {
      planId,
      quantity: planToQuantityMap[planId],
    };
  });
}

/**
 * Returns the correct banner copy depending on whether the
 * bundle is multi-item and has discount for amount off
 * @param {Boolean} multiItem Is this multi-item?
 * @param {Object} discountData Discount Data object REQUIRED
 * @param {Object} promotion Promotion object
 * @returns {String} Translated string for banner
 */
export function getBannerCopy(multiItem, promotionDetails) {
  return multiItem
    ? promotionDetails.amountOff
      ? promotionDetailsAmountMessage(
          promotionDetails.promotion.name,
          promotionDetails.amountOff,
        )
      : intl.t(
          "build-bundle.slides.results.multi-bundle.unlock-percent",
          "You unlocked a {off}% discount",
          { off: promotionDetails.percentOff },
        )
    : intl.t(
        "build-bundle.slides.results.single-bundle.banner",
        "Add another to save {copy}",
        { copy: promotionDetails.formattedValue },
      );
}

export function getFeaturedBundleName(featuredBundle) {
  const { title, labels } = featuredBundle;
  let name = title;
  labels?.forEach((label) => {
    name += ` ${label}`;
  });

  return name;
}

export function getStylizedFeaturedBundleName(featuredBundle) {
  const { title, labels } = featuredBundle;

  return (
    <>
      {title}
      {labels.map((label) => (
        <em key={`styled-${label}`}>
          {` `}
          {label}
        </em>
      ))}
    </>
  );
}

function promotionDetailsAmountMessage(promotionName, amountOff) {
  return promotionName === "bundle"
    ? intl.t(
        "build-bundle.slides.results.multi-bundle.unlock-initial-promo",
        "Bundle and save {off} on your first order",
        {
          off: intl.formatCurrency(amountOff, {
            round: true,
          }),
        },
      )
    : intl.t(
        "build-bundle.slides.results.multi-bundle.unlock",
        "You unlocked a {off} discount",
        {
          off: intl.formatCurrency(amountOff, {
            round: true,
          }),
        },
      );
}

export function getProductOfferPricing(ritualProductOffer, currency) {
  const initialPlans = ritualProductOffer.ritualProductOfferPlans
    .map((plan) => plan.ritualInitialPlan)
    .filter((plan) => plan.currency === currency.toLowerCase());

  const futurePlans = ritualProductOffer.ritualProductOfferPlans
    .map((plan) => plan.ritualFuturePlan)
    .filter((plan) => plan.currency === currency.toLowerCase());

  const price = initialPlans.reduce((acc, plan) => acc + plan.amount, 0) / 100;
  const futurePrice =
    futurePlans.reduce((acc, plan) => acc + plan.amount, 0) / 100;
  const savings = Math.floor((futurePrice * 100 - price * 100) / 100).toFixed(
    0,
  );

  return {
    price,
    futurePrice,
    savings,
    futurePlans,
  };
}

export function getBundlePromotion(promotion, activePromotions, flag) {
  return promotion?.slug === flag?.base_promo_slug
    ? activePromotions?.find(
        (promo) => promo?.slug === flag?.bundle_promo_slug,
      ) || promotion
    : promotion;
}